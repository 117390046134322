import React, { createContext, useState, useContext, useRef } from 'react'

const initialState = {
  "title" : "The Fallen"
};

const store = createContext();
const { Provider } = store;

const StateProvider = ( { children } ) => {
  const [globalState, setState] = useState(initialState);
  const globalRef = useRef({test:1});
  const setGlobalState = ( merge ) => setState({ ...globalState, ...merge });
  const setGlobalRef = ( merge ) => { globalRef.current = { ...globalRef.current, ...merge }; };
  return <Provider value={{ globalState, setGlobalState, globalRef, setGlobalRef }}>{children}</Provider>;

};

const useGlobalState = () => {
  return useContext(store);
}

export { store, StateProvider, useGlobalState }
