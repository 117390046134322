import React, { Fragment, Suspense, lazy } from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import * as serviceWorker from "./serviceWorker";
import Pace from "./shared/components/Pace";
import { StateProvider } from "shared/GlobalContext";
import ScrollToTop from "shared/components/ScrollToTop";

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));

const Main = lazy(() => import("components/Main"));

function App() {

  return (
    <StateProvider>
      <BrowserRouter>
        <ScrollToTop />
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          <Pace color={theme.palette.primary.light} />
          <Suspense fallback={<Fragment />}>
            <Switch>
              <Route path="/c">
                <LoggedInComponent />
              </Route>
              <Route>
                <Main />
              </Route>
            </Switch>
          </Suspense>
        </MuiThemeProvider>
      </BrowserRouter>
    </StateProvider>
  );
}

serviceWorker.register();

export default App;
